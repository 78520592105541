<template>
    <div>

        <div class="d-flex align-items-center justify-content-center" style="height: 90vh;">

            <div class="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-br-primary rounded bd bd-white-1">
                <div class="signin-logo tx-center tx-28 tx-bold tx-white"><vue-qrcode v-if="qr_code !== null" :value="qr_code" /></div>
                <div class="tx-center mg-b-20">VARS 2 FACTOR AUTHENTICATION</div>
                <p>Please scan the above barcode using Google Authenticator</p>

                <!--                <div class="text-danger mg-b-10" vars-id="error">No Errors Sir!</div>-->
                <div class="form-group">
                    <input type="text" v-on:keyup.enter="auth" v-model="otp" maxlength="6" class="form-control form-control-dark">
                </div>

                <p class="text-danger" v-if="error">Failed to authenticate.</p>

                <submit-button @click="auth" :background="'btn-info'" :text="'Authenticate'" :clicked="waiting" />

            </div><!-- login-wrapper -->
        </div><!-- d-flex -->

    </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "GoogleTwoFactor",
    components: {
        SubmitButton,
        VueQrcode
    },
    props: {
        route: String,
    },
    data(){
        return {
            secret: "",
            qr_code: null,
            otp: "",
            error: false,
            waiting: false,
        }
    },
    mounted() {
        axios.get(`https://api.varsanpr.com/api/auth/2fa`, {
            headers: authHeader()
        })
        .then(response => {
            this.qr_code = `${response.data.otpauthurl}`;
            console.log(this.qr_code);
        })
        .catch(error => {
            this.$error("Unable to generate a QR code at this time.");
        });
    },
    methods: {
        auth(){
            this.error = false;
            this.waiting = true;
            axios.post(`https://api.varsanpr.com/api/auth/2fa`, {
                token: this.otp
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.waiting = false;
                this.$success("Successfully validated your 2FA");
                if(this.route !== null && this.route !== undefined){
                    this.$router.push({name: this.route});
                }else{
                    this.$router.push('/claims/management');
                }
            })
            .catch(error => {
                this.$error("Failed to validate your 2FA");
                this.error = true;
                this.waiting = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
